import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Button, Col, message, Popover, Row, Space, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import sdk from "@/sdk";

import { WithdrawalRequest, WithdrawalStatus } from "@/graphql";
import moment from "moment";
import { RefundUserDetailModal } from "./RefundUserDetailModal";
import { debounce } from "ts-debounce";
import Search from "antd/lib/input/Search";
import {} from "@/utils/decimal";

export function RefundListWidget() {
  const [selectCancel, setSelectCancel] = useState<string | null>(null);
  const [selectUser, setSelectUser] = useState<WithdrawalRequest | null>(null);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(30);

  const { data, isLoading, refetch } = useQuery(
    ["getAllWithdrawalRequest", page],
    async () =>
      sdk.allWithdrawalRequests({
        limit,
        page,
        status: WithdrawalStatus.Pending,
      }),
    {
      select: (data) => data.allWithdrawalRequests,
    }
  );

  const { isLoading: mutateLoading, mutate } = useMutation({
    mutationKey: ["cancelRefund"],
    mutationFn: async () =>
      sdk.cancelWithdrawalRequest({ requestId: selectCancel + "" }),
    onSuccess: (data) => {
      if (data.cancelWithdrawalRequest) {
        refetch();
        setSelectCancel(null);
        message.success("Амжилттай", 2);
      }
    },
    onError: (err) => {
      setSelectCancel(null);
      message.error((err as Error).message);
    },
  });

  const cancelRefund = () => {
    return (
      <Col>
        <Row>Та цуцлахдаа итгэлтэй байна уу</Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 10,
          }}
        >
          <Button
            type="primary"
            danger
            style={{ width: "49%" }}
            onClick={() => mutate()}
            disabled={mutateLoading}
          >
            Тийм
          </Button>
          <Button
            type="primary"
            style={{ width: "49%" }}
            disabled={mutateLoading}
            onClick={() => setSelectCancel(null)}
          >
            Үгүй
          </Button>
        </div>
      </Col>
    );
  };

  const calculateAmount = useCallback((value: WithdrawalRequest) => {
    //ask oleg
    let amount = 0;
    value.bonds.map((item) => (amount += item.amount));
    value.estate.map((item) => (amount += item.amount));
    value.stocks.map(
      (item) => (amount += item.stock.unitPrice * item.quantity)
    );

    return amount.toFixed(2);
  }, []);

  // const filteredData = useMemo(() => {
  //   if (data) {
  //     return data.requests.filter(
  //       (item) =>
  //         item.user.email?.includes(search) ||
  //         item.user.phoneNo?.includes(search)
  //     );
  //   }
  //   return [];
  // }, [search]);

  return (
    <div>
      <RefundUserDetailModal setUser={setSelectUser} user={selectUser} />
      <div className="page-header">
        <h3>Буцаалтын жагсаалт</h3>
        <div style={{ display: "flex", width: "30%" }}>
          <Search
            placeholder="Хайх утгаа оруулна уу."
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            size="small"
            bordered
            loading={isLoading}
            dataSource={data?.requests ?? []}
            pagination={{
              current: page + 1,
              pageSize: limit,
              total: data?.totalItems,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                setPage(page - 1);
                setLimit(pageSize);
              },
            }}
            columns={[
              {
                title: "Утасны дугаар эсвэл и-мейл хаяг",
                dataIndex: "id",
                key: "number",
                render: (_, record) => (
                  <span>{record.user.email ?? record.user.phoneNo}</span>
                ),
              },
              {
                title: "Хүсэлт илгээсэн огноо",
                dataIndex: "date",
                key: "date",
                render: (_, record) => (
                  <span>
                    {moment(record.createdAt).format("YYYY/MM/DD HH:mm:ss")}
                  </span>
                ),
              },
              {
                title: "Дүн",
                dataIndex: "amount",
                key: "amount",
                render: (_, record) => (
                  <div>{calculateAmount(record as WithdrawalRequest)}</div>
                ),
              },
              {
                title: "",
                dataIndex: "actions",
                key: "actions",
                render: (_, record) => (
                  <Space>
                    <Button
                      icon={<EyeOutlined />}
                      type="dashed"
                      onClick={() => setSelectUser(record as WithdrawalRequest)}
                    />
                    <Popover
                      trigger="click"
                      content={cancelRefund}
                      open={selectCancel === record._id}
                    >
                      <Button
                        type="primary"
                        danger
                        onClick={() => setSelectCancel(record._id)}
                      >
                        Цуцлах
                      </Button>
                    </Popover>
                  </Space>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
